<template>
  <v-container v-if="indicators">
    <v-row>
      <v-col cols="12">
        <h3>Dashboard</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-scrollfadein>
        <v-card class="text-center" @click="dialogMyOpportunities = true">
          <v-card-text>
            <h4>Ponderado de Oportunidades</h4>
            <h2 :class="darkTheme ? 'text-white' : 'text-black'">
              {{ indicators.weighted | currency }}
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-scrollfadein>
        <v-card class="text-center" @click="dialogCuota = true">
          <v-card-text>
            <h4>Cuota anual</h4>
            <h2 :class="darkTheme ? 'text-white' : 'text-black'">
              {{ (indicators.fee / 100) | percent(2) }}
            </h2>
            <TwoLevelPie :info="getCuotaChart" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-scrollfadein>
        <v-card class="text-center" @click="dialogHitRate = true">
          <v-card-text>
            <h4>Hit Rate</h4>
            <h2 :class="darkTheme ? 'text-white' : 'text-black'">
              {{ (indicators.hit / 100) | percent(2) }}
            </h2>
            <TwoLevelPie :info="getHitrate" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <DialogFullScreen
      title="Mis oportunidades"
      :visible="dialogMyOpportunities"
      @closeDialog="closeDialogs"
    >
      <v-row class="mt-2">
        <v-col cols="12" class="text-center mb-6">
          <v-btn-toggle
            v-model="selectedQ"
            mandatory
            @change="getOpportunitiesByQ(true)"
          >
            <v-btn> Todos </v-btn>
            <v-btn> Q1 </v-btn>
            <v-btn> Q2 </v-btn>
            <v-btn> Q3 </v-btn>
            <v-btn> Q4 </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <DataTable
        :data="getOpportunities"
        disableSort
        hideFooter
        v-if="opportunitiesByQ"
        v-scrollfadein
      />
    </DialogFullScreen>
    <DialogFullScreen
      title="Cuota"
      :visible="dialogCuota"
      @closeDialog="closeDialogs"
    >
      <v-row class="mt-2" v-if="cuotaByQ">
        <v-col cols="12" class="text-center mb-6">
          <v-btn-toggle
            v-model="selectedQ"
            mandatory
            @change="getCuotaByQ(true)"
          >
            <v-btn> Todos </v-btn>
            <v-btn> Q1 </v-btn>
            <v-btn> Q2 </v-btn>
            <v-btn> Q3 </v-btn>
            <v-btn> Q4 </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12" v-scrollfadein>
          <v-card class="text-center">
            <v-card-text>
              <h4>Cuota anual</h4>
              <h2 :class="darkTheme ? 'text-white' : 'text-black'">
                {{ indicators.cuota | currency }}
              </h2>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-scrollfadein>
          <v-card class="text-center">
            <v-card-text>
              <h4>Cuota por Q</h4>
              <h2 :class="darkTheme ? 'text-white' : 'text-black'">
                {{ (indicators.fee / 100) | percent(2) }}
              </h2>
              <TwoLevelPie :info="getCuotaChart" />
              <h4>
                Te faltan {{ (cuotaByQ.goal - cuotaByQ.won) | currency }} MXN de
                cierre este Q para tu bono de velocidad
              </h4>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </DialogFullScreen>
    <DialogFullScreen
      title="Hit Rate"
      :visible="dialogHitRate"
      @closeDialog="closeDialogs"
    >
      <v-row class="mt-2" v-if="hitRateByQ">
        <v-col cols="12" class="text-center">
          <v-btn-toggle
            v-model="selectedQ"
            mandatory
            @change="getHitRateByQ(true)"
          >
            <v-btn> Todos </v-btn>
            <v-btn> Q1 </v-btn>
            <v-btn> Q2 </v-btn>
            <v-btn> Q3 </v-btn>
            <v-btn> Q4 </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12" v-scrollfadein>
          <v-card class="text-center">
            <v-card-text>
              <h4>Hit Rate</h4>
              <p>Porcentaje de efectividad</p>
              <h2 :class="darkTheme ? 'text-white' : 'text-black'">
                {{ (indicators.hit / 100) | percent(2) }}
              </h2>
              <TwoLevelPie :info="getHitrate" />
              <p>
                Tu Hit Rate se calcula con base en Pipeline calificado y en tus
                Ventas cerradas
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" v-scrollfadein>
          <v-card class="text-center my-card">
            <v-card-text>
              <h4>Oportunidades ganadas este Q</h4>
              <h2 :class="darkTheme ? 'text-white' : 'text-black'">
                {{ hitRateByQ.Won ? hitRateByQ.Won : 0 }}
              </h2>
              <v-icon :class="darkTheme ? '' : 'success--text'"
                >mdi-check-bold</v-icon
              >
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" v-scrollfadein>
          <v-card class="my-card">
            <v-card-text class="text-center">
              <h4>Oportunidades perdidas este Q</h4>
              <h2 :class="darkTheme ? 'text-white' : 'text-black'">
                {{ hitRateByQ.Lost ? hitRateByQ.Lost : 0 }}
              </h2>
              <v-icon :class="darkTheme ? '' : 'warning--text'"
                >mdi-alert</v-icon
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </DialogFullScreen>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import TwoLevelPie from '@/components/charts/TwoLevelPie.vue'
import DialogFullScreen from '@/components/DialogFullScreen.vue'
import DataTable from '@/components/DataTable.vue'

export default {
  components: { TwoLevelPie, DialogFullScreen, DataTable },
  data() {
    return {
      indicators: null,
      dialogMyOpportunities: false,
      dialogCuota: false,
      dialogHitRate: false,
      opportunitiesByQ: null,
      cuotaByQ: null,
      hitRateByQ: null,
      selectedQ: 0
    }
  },
  methods: {
    test(test) {
      console.log(test)
    },
    closeDialogs() {
      this.dialogMyOpportunities = false
      this.dialogCuota = false
      this.dialogHitRate = false
      this.selectedQ = 0
    },
    getDashboard(showOverlay) {
      axios
        .get(
          this.$endpointsBaseUrl +
            'Dashboards/Get_Mydashboard?userid=' +
            this.userData.UserId,
          {
            showOverlay: showOverlay
          }
        )
        .then((response) => {
          this.indicators = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getOpportunitiesByQ(showOverlay) {
      let headers = {
        userid: this.userData.UserId,
        q: this.selectedQ
      }

      axios
        .get(this.$endpointsBaseUrl + 'Opportunities/Get_OpportunitiesByQs', {
          showOverlay: showOverlay,
          headers: headers
        })
        .then((response) => {
          this.opportunitiesByQ = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getCuotaByQ(showOverlay) {
      let headers = {
        userid: this.userData.UserId,
        q: this.selectedQ
      }

      axios
        .get(this.$endpointsBaseUrl + 'Dashboards/Get_MyCuota', {
          showOverlay: showOverlay,
          headers: headers
        })
        .then((response) => {
          this.cuotaByQ = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getHitRateByQ(showOverlay) {
      let headers = {
        userid: this.userData.UserId,
        q: this.selectedQ
      }

      axios
        .get(this.$endpointsBaseUrl + 'Dashboards/Get_MyHitrate', {
          showOverlay: showOverlay,
          headers: headers
        })
        .then((response) => {
          this.hitRateByQ = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    }
  },
  computed: {
    ...mapState('account', ['userData', 'darkTheme']),
    getHitrate() {
      return [
        {
          category: 'Hit Rate',
          value: this.indicators.hit
        },
        {
          category: 'Restante',
          value: 100 - this.indicators.hit,
          fill: '#dedede'
        }
      ]
    },
    getCuotaChart() {
      return [
        {
          category: 'Quota',
          value: this.indicators.fee
        },
        {
          category: 'Restante',
          value: this.indicators.fee > 100 ? 0 : 100 - this.indicators.fee,
          fill: '#dedede'
        }
      ]
    },
    getOpportunities() {
      let data = {
        headers: [
          { text: 'Oportunidad', value: 'oportunidad' },
          { text: 'Monto', value: 'monto' },
          { text: 'Ponderado', value: 'ponderado' }
        ],
        items: []
      }
      let sumaMonto = 0
      let sumaPonderado = 0

      this.opportunitiesByQ.forEach((element) => {
        let ponderado =
          element.Status.Label == 'Won'
            ? element.Opportunities.Budget
            : element.Opportunities.Budget *
              (element.Opportunities.Probability / 100) *
              (element.SubStage.Percentage / 100)
        let item = {
          oportunidad: element.Opportunities.Name,
          monto: this.$options.filters.currency(element.Opportunities.Budget),
          ponderado: this.$options.filters.currency(ponderado)
        }
        sumaMonto += element.Opportunities.Budget
        sumaPonderado += ponderado
        data.items.push(item)
      })

      data.items.push({
        oportunidad: 'Totales',
        monto: this.$options.filters.currency(sumaMonto),
        ponderado: this.$options.filters.currency(sumaPonderado)
      })

      return data
    }
  },
  mounted() {
    this.getDashboard(true)
    this.getOpportunitiesByQ(true)
    this.getCuotaByQ(true)
    this.getHitRateByQ(true)
  }
}
</script>
