<template>
  <div id="amTLPie" ref="tlPieChart"></div>
</template>

<script>
import { mapState } from 'vuex'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4themes_material from '@amcharts/amcharts4/themes/material'
import am4themes_dark from '@amcharts/amcharts4/themes/dark'
am4core.options.autoDispose = true

// import chartData from "@/assets/data/pieData.json";

export default {
  props: {
    info: {
      type: Array,
      required: true
    }
  },
  methods: {
    renderChart() {
      am4core.useTheme(am4themes_animated)
      this.darkTheme
        ? am4core.useTheme(am4themes_dark)
        : am4core.useTheme(am4themes_material)

      let chart = am4core.create(this.$refs.tlPieChart, am4charts.PieChart)
      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart.innerRadius = am4core.percent(70)

      // Add second series
      let pieSeries = chart.series.push(new am4charts.PieSeries())
      pieSeries.dataFields.value = 'value'
      pieSeries.dataFields.category = 'category'
      pieSeries.slices.template.states.getKey(
        'hover'
      ).properties.shiftRadius = 0
      pieSeries.slices.template.states.getKey('hover').properties.scale = 1
      pieSeries.slices.template.propertyFields.fill = 'fill'

      // Add data
      pieSeries.data = this.info
      pieSeries.labels.template.disabled = true
      pieSeries.tooltip.disabled = true
    }
  },
  mounted() {
    this.renderChart()
  },
  beforeDestroy() {
    if (this.chart) {
      am4core.disposeAllCharts()
    }
  },
  computed: {
    ...mapState('account', ['darkTheme'])
  },
  watch: {
    darkTheme() {
      am4core.unuseAllThemes()
      if (this.chart) {
        am4core.disposeAllCharts()
      }
      this.renderChart()
    }
  }
}
</script>
