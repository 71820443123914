<template>
  <v-data-table
    class="elevation-6"
    :headers="data.headers"
    :items="data.items"
    :search="search"
    :hide-default-header="hideHeader"
    :hide-default-footer="hideFooter"
    :disable-sort="disableSort"
  >
    <template v-slot:top v-if="searchEnabled">
      <v-text-field
        v-model="search"
        label="Buscar"
        class="mx-4"
        prepend-icon="mdi-magnify"
      ></v-text-field>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    data: { type: Object, required: true },
    searchEnabled: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    disableSort: { type: Boolean, default: false }
  },
  data() {
    return {
      search: ''
    }
  }
}
</script>
